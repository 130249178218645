import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { Breadcrumbs } from "../../components/Navigation/Breadcrumbs"
import AppFooter from '../../components/CoreUI/AppFooter'
import AppHeader from '../../components/CoreUI/AppHeader'
import AppSidebar from '../../components/CoreUI/AppSidebar'
import AppSidebarFooter from '../../components/CoreUI/AppSidebarFooter'
import AppSidebarForm from '../../components/CoreUI/AppSidebarForm'
import AppSidebarHeader from '../../components/CoreUI/AppSidebarHeader'
import AppSidebarMinimizer from '../../components/CoreUI/AppSidebarMinimizer'
import SidebarNavMenu from '../../components/Navigation/SidebarNavMenu'
import { ViewerLoading } from '../../components/Authorization/ViewerLoading'
import { LoadingIndicator } from '../../components/Status/LoadingIndicator'

import { Admin, NotAdmin } from '../../components/Authorization/Admin'
import { ViewerProvider } from '../../components/Authorization/ViewerProvider'

import { Unauthorized } from '../../views/Unauthorized/Unauthorized'

// routes config
import routes from '../../routes'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'


class DefaultLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      account: null,
      breadcrumbs: routes || [ ],
    }

    this.setAccount = this.setAccount.bind(this)
    this.addBreadcrumb = this.addBreadcrumb.bind(this)
  }

  setAccount(account) {
    this.setState({
      account: account
    });
  }

  addBreadcrumb(newBreadcrumbs) {

    if (!newBreadcrumbs) {
      return
    }
    
    if (!Array.isArray(newBreadcrumbs) && typeof newBreadcrumbs === 'object') {
      newBreadcrumbs = [ newBreadcrumbs ]
    }

    if (!Array.isArray(newBreadcrumbs)) {
      return
    }

    const validated = newBreadcrumbs.filter(breadcrumb => breadcrumb.path && breadcrumb.path.length > 0)

    if (validated.length === 0) {
      return
    }

    let breadcrumbs = this.state.breadcrumbs

    validated.forEach(breadcrumb => {
      let idx = breadcrumbs.findIndex(element => {
        return element.path && element.path === breadcrumb.path
      })
      if (idx > -1) {
        breadcrumbs[idx] = breadcrumb
      } else {
        breadcrumbs = [ ...breadcrumbs, breadcrumb ]
      }
    })

    this.setState({
      breadcrumbs: breadcrumbs
    });
  }

  render() {
    let commonProps = {
      account: this.state.account,
      setAccount: this.setAccount,
      addBreadcrumb: this.addBreadcrumb,
    }

    return (
        <ViewerProvider>
          <div className="app">
            <AppHeader fixed>
              <DefaultHeader props={this.props} {...commonProps}/>
            </AppHeader>
            <div className="app-body">
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <SidebarNavMenu {...this.props} />
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
              <main className="main">
                <NotAdmin>
                  <Breadcrumbs routes={routes.filter(route => route.path === "/")} />
                </NotAdmin>
                <Admin>
                  <Breadcrumbs routes={this.state.breadcrumbs} />
                </Admin>
                <Container fluid>
                  <ViewerLoading loading={<LoadingIndicator/>}>
                    <NotAdmin>
                      <Unauthorized/>
                    </NotAdmin>
                    <Admin>
                      <Switch>
                        {
                          routes.map((route, idx) => {

                            if (!route.component) {
                              return null
                            }

                            return <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                <route.component {...this.props} {...commonProps} />
                              )} />
                          },
                        )}
                        <Redirect from="/" to="/accounts" />
                      </Switch>
                    </Admin>
                  </ViewerLoading>
                </Container>
              </main>
            </div>
            <AppFooter>
              <DefaultFooter />
            </AppFooter>
          </div>
        </ViewerProvider>
    );
  }
}

export default DefaultLayout;
