import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/pro-regular-svg-icons'

class ResetButton extends Component {

  render() {

    let props = {color: "danger", ...this.props}

    return (
      <Button {...props}><FontAwesomeIcon icon={faUndo} /> Reset</Button>
    )
  }
}

export { ResetButton }