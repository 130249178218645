import React, { Component } from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import './App.css';
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import { Auth } from "aws-amplify";

//Apollo
import { ApolloClient } from "apollo-client"
import { ApolloProvider } from "react-apollo"
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory"

import introspectionQueryResultData from './schema/fragmentTypes.json'
import { PortalAPI } from "./utility/PortalAPI"

import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { LoginPages } from './views/Pages';

// https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher })

const client = new ApolloClient({
  link: PortalAPI.httpLink,
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only"
    },
    query: {
      fetchPolicy: "network-only"
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      username: "",
    }

    this.handleLogin = this.handleLogin.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout() {
    try {
      Auth.signOut().then(_ => {
        client.resetStore()
          this.setState({
            isAuthenticated: false,
            username: ""
        })
      })
    } catch (e) {
        console.log("Error " + e)
    }
  }
  
  handleLogin(username) {
      client.resetStore().then(() => {
      
        this.setState({
          isAuthenticated: true,
          username: username,
        })
        
      })
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userId: this.state.username,
      handleLogin: this.handleLogin,
      handleLogout: this.handleLogout
    };
    
    return (
      <ApolloProvider client={client}>
        <HashRouter>
          <Switch>
            <UnauthenticatedRoute path="/Login" component={LoginPages} props={childProps}/>
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} props={childProps} />
          </Switch>
        </HashRouter>
      </ApolloProvider>
    );
  }
}

export default App;
