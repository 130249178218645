import { orderBy } from 'lodash'
import { SettingTypeObjectsObject } from '../enums/enumObjects'

export let sorted = {

  accounts: {
    byName: accounts => {
      return orderBy(accounts, [acct => acct.name.toLowerCase()], ['asc'])
    }
  },
  settings: {
    byName: settings => {
      return orderBy(settings, [setting => setting.name.toLowerCase()], ['asc'])
    },
    byTypeDescription: settings => {
      return orderBy(settings, [setting => {
        let type = SettingTypeObjectsObject[setting.name] || { description: setting.name }
        return type.description
      }], ['asc'])
    },
  },
  userGroups: {
    byName: userGroups => {
      return orderBy(userGroups, [userGroup => (userGroup.name || "").toLowerCase()], ['asc'])
    },
  },
  users: {
    byName: users => {
      return orderBy(users, [user => (user.name || "").toLowerCase()], ['asc'])
    },
    byEmail: users => {
      return orderBy(users, [user => (user.email || "").toLowerCase()], ['asc'])
    },
  }
}