import React, { Component } from 'react'
import { SidebarMenu } from './SidebarMenu'
import { SidebarMenuItem, SidebarMenuItemDropdown, SidebarMenuItemTitle, SidebarMenuItemDivider } from './SidebarMenuItem'
import { Admin, NotAdmin } from '../Authorization/Admin'
import { SystemAdmin } from '../Authorization/SystemAdmin'
import { ViewerLoading } from '../Authorization/ViewerLoading'
import { LoadingIndicator } from '../Status/LoadingIndicator'
import {
  faList,
  faWrench,
  faAddressCard,
  faServer,
  faUsers,
  faUsersCog,
  faCogs,
  faPlusCircle,
  faKey,
  faReceipt,
  faFile,
} from '@fortawesome/pro-regular-svg-icons'
import {
  accountPathForAccount,
  connectionsPathForAccount,
  usersPathForAccount,
  userGroupsPathForAccount,
  reportTemplatesPathForAccount,
} from '../../routes'
import { AccountsQuery } from '../../schema/queries/Account'
import { Query } from 'react-apollo'
import { sorted } from '../../schema/sort/sorted'
import { filtered } from '../../schema/sort/filtered'
import { consts } from '../../schema/consts/consts'

class SidebarAccountDropdownItem extends Component {
  render() {

    let account = this.props.account

    return (
      <SidebarMenuItemDropdown name={account.name} {...this.props}>
        <SidebarMenuItem url={accountPathForAccount(account.id) + "/general"} faIcon={faAddressCard}>General</SidebarMenuItem>
        <SidebarMenuItem url={connectionsPathForAccount(account.id)} faIcon={faServer}>Connections</SidebarMenuItem>
        <SidebarMenuItem url={usersPathForAccount(account.id)} faIcon={faUsers}>Users</SidebarMenuItem>
        <SidebarMenuItem url={userGroupsPathForAccount(account.id)} faIcon={faPlusCircle}>Groups</SidebarMenuItem>
        <SidebarMenuItem url={reportTemplatesPathForAccount(account.id)} faIcon={faFile}>Reports</SidebarMenuItem>
        <SidebarMenuItem url={accountPathForAccount(account.id) + '/settings'} faIcon={faCogs}>Settings</SidebarMenuItem>
        <SidebarMenuItem url={accountPathForAccount(account.id) + '/admins'} faIcon={faUsersCog}>Administrators</SidebarMenuItem>
        <SidebarMenuItem url={accountPathForAccount(account.id) + '/billing'} faIcon={faReceipt}>Billing</SidebarMenuItem>
      </SidebarMenuItemDropdown>
    )
  }
}

class SidebarNavMenu extends Component {
  render() {
    return (
      <SidebarMenu>
        <ViewerLoading loading={<React.Fragment></React.Fragment>}>
          <SystemAdmin>
            <SidebarMenuItemTitle>System Administrator</SidebarMenuItemTitle>
            <SidebarMenuItem url="/accounts" faIcon={faList} exact>All Accounts</SidebarMenuItem>
            <SidebarMenuItem url="/graphiql" faIcon={faWrench}>GraphiQL</SidebarMenuItem>
            <SidebarMenuItem url={`/accounts/${consts.accounts.system.id}`} faIcon={faKey}>System</SidebarMenuItem>
            <SidebarMenuItemDivider/>
          </SystemAdmin>
          <Admin>
            <SidebarMenuItemTitle>Account Administrator</SidebarMenuItemTitle>
            <Query query={AccountsQuery}>
              {
                ({ loading, error, data }) => {

                  if (loading) {
                    return <SidebarMenuItemTitle><LoadingIndicator /></SidebarMenuItemTitle>
                  }

                  if (error) {
                    console.error(error)
                    throw error
                  }

                  let nodes = [ ]

                  let multiAccount = data.accounts.length > 0

                  let accountNodes = filtered.accounts.nonSystem(sorted.accounts.byName(data.accounts)).map(acct => { return (
                    <SidebarAccountDropdownItem key={acct.id} account={acct} defaultOpen={!multiAccount} />
                  )})

                  return [...nodes, ...accountNodes]
                }
              }
            </Query>
          </Admin>
          <NotAdmin>
            <SidebarMenuItemTitle>User</SidebarMenuItemTitle>
          </NotAdmin>
        </ViewerLoading>
      </SidebarMenu>
    )
  }
}

export default SidebarNavMenu
export { SidebarNavMenu }