import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import { Circle } from 'rc-progress'
import { PropTypes } from 'prop-types'

class CountdownCircle extends Component {
  constructor(props) {
    super(props)

    let time = this.props.time || 1000

    this.state = {
      interval: this.props.interval || 100,
      time: time,
      remaining: time,
      percent: 100,
    }

    this.tick = this.tick.bind(this)
  }

  componentDidMount() {
    this.timerID = setInterval(() => {this.tick()}, parseFloat(this.state.interval))
  }

  tick() {
    let state = this.state
    let remaining = parseFloat(state.remaining) - parseFloat(state.interval)
    let percent = (remaining / parseFloat(state.time)) * 100

    if (remaining <= 0) {

      remaining = 0
      percent = 0
      clearInterval(this.timerID)

      if (this.props.onTimeout) {
        this.props.onTimeout()
      }
    }

    this.setState({
      remaining: remaining,
      percent: percent,
    })
  }

  render() {

    let { color, size, strokeWidth } = this.props

    return (
      <span style={{ display: 'inline-block', width: size || '1rem' }}>
        <Circle strokeColor={color} strokeWidth={strokeWidth} percent={this.state.percent}/>
      </span>
    )
  }
}

class ErrorAlert extends Component {
  constructor(props) {
    super(props)

    this.onDismiss = this.onDismiss.bind(this)
  }

  componentDidMount() {

  }
  
  componentWillUnmount() {

  }

  onDismiss() {
    if (!this.props.onDismiss) {
      return
    }
    this.props.onDismiss()
  }

  render() {

    let props = this.props

    let isOpen = props.isOpen || !!props.error

    if (props.error) {
      console.error(props.error)

    } else if (isOpen) {
      console.error(props.message || "An error occurred.")
    }

    return (
      <div className="fixed-top d-inline-flex justify-content-center">
        <Alert className="text-center align-middle" color="danger" isOpen={isOpen} toggle={this.onDismiss}>
          <span className="align-middle">
            {props.error ? props.error.message : props.message}{' '}
            <CountdownCircle time={5000} interval={5} color='rgb(145, 2, 2)' size='1rem' strokeWidth={8} onTimeout={this.onDismiss} />
          </span>
        </Alert>
      </div>
    )
  }
}

ErrorAlert.propTypes = {
  error: PropTypes.instanceOf(Error),
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onDismiss: PropTypes.func,  
}

ErrorAlert.defaultProps = {}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
          <div>
            <ErrorAlert error={this.state.error} />
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

class ErrorAlertBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {

    let state = this.state
    let props = this.props
    let error = state.error

    return (
      <React.Fragment>
        {
          !error ? null : <Alert className="text-center" color="danger" isOpen={!!error}>
                            {error.message || error.toString()}
                          </Alert>
        }
        {props.children}
      </React.Fragment>
    )
  }  
}

export { ErrorAlert, ErrorBoundary, ErrorAlertBoundary }