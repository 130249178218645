import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/pro-regular-svg-icons'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <div style={{width: '100%'}}>
        <span><a style={{display: "table-cell"}} href="http://www.expertek.com" rel="noopener noreferrer" target="_blank">Expertek Systems, Inc.</a></span>
        <span><small><a href="privacy-policy-english.html" rel="noopener noreferrer" target="_blank">{' | '}Privacy Policy</a></small></span>
        <small className="float-right"><FontAwesomeIcon icon={faCopyright} size="sm" /> { (new Date()).getFullYear() } Expertek Systems, Inc. All rights reserved.</small>
      </div>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
