import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { NavItem, NavLink as RsNavLink } from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SidebarMenuItemDropdown extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.open || props.defaultOpen,
    }

    this.toggleIsOpen = this.toggleIsOpen.bind(this)
  }

  toggleIsOpen(e) {
    e.preventDefault()
    
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {

    let { name, children } = this.props

    let DropdownButton = styled.button`

    border-width: 0;
      width: 100%;

      &:focus {
        outline: none;
      }
    `

    let faIcon = this.props.faIcon

    return (
      <li className={"nav-item nav-dropdown" + (this.state.isOpen ? " open" : "")}>
        <DropdownButton className="nav-link nav-dropdown-toggle text-left" onClick={this.toggleIsOpen}>
          {faIcon ? <FontAwesomeIcon className='nav-icon' icon={faIcon} /> : null}
          {name}
        </DropdownButton>
        <ul className="nav-dropdown-items">
          {children}
        </ul>
      </li>
    )
  }
}

SidebarMenuItemDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  faIcon: PropTypes.object,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
}

SidebarMenuItemDropdown.defaultProps = {
  defaultOpen: false,
}

class SidebarMenuItemLink extends Component {
  constructor(props) {
    super(props);

    this.hideMobile = this.hideMobile.bind(this)
  }

  hideMobile() {
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show')
    }
  }

  isExternal(url) {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  }

  render() {

    let { url, className, faIcon, cssIcon, linkClassName, children, exact } = this.props

    let useFaIcon = !!faIcon
    let useCssIcon = !useFaIcon && cssIcon

    return (
      <NavItem className={className}>
        {
          this.isExternal(url) ?
            <RsNavLink href={url} className={linkClassName} active>
              {useFaIcon ? <FontAwesomeIcon className='nav-icon' icon={faIcon} /> : null}
              {useCssIcon ? <i className={cssIcon} /> : null}
              {children}
            </RsNavLink> :
            <NavLink to={url} className={linkClassName} activeClassName="active" exact={exact} onClick={this.hideMobile}>
              {useFaIcon ? <FontAwesomeIcon className='nav-icon' icon={faIcon} /> : null}
              {useCssIcon ? <i className={cssIcon} /> : null}
              {children}
            </NavLink>
        }
      </NavItem>
    );
  }
}

SidebarMenuItemLink.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  className: PropTypes.string,
  faIcon: PropTypes.object,
  cssIcon: PropTypes.string,
  linkClassName: PropTypes.string,
  exact: PropTypes.bool,
}

class SidebarMenuItem extends Component {
  constructor(props) {
    super(props);

    this.hideMobile = this.hideMobile.bind(this)
  }

  hideMobile() {
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show')
    }
  }

  render() {

    let { url, className, faIcon, cssIcon, children, variant, exact } = this.props

    let linkProps = {
      url: url,
      className: className,
      faIcon: faIcon,
      cssIcon: cssIcon,
      linkClassName: classNames('nav-link', variant ? `nav-link-${variant}` : ''),
      exact: exact,
    }

    return (
      <SidebarMenuItemLink {...linkProps} >
        {children}
      </SidebarMenuItemLink>
    )
  }
}

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  className: PropTypes.string,
  faIcon: PropTypes.object,
  cssIcon: PropTypes.string,
  variant: PropTypes.object,
  exact: PropTypes.bool,
}

class SidebarMenuItemTitle extends Component {
  render() {
    let props = this.props
    return (
      <li className={classNames('nav-title', props.className || '')}>{props.children}</li>
    )
  }
}

SidebarMenuItemTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

class SidebarMenuItemDivider extends Component {
  render() {
    let props = this.props
    return (
      <li className={classNames('divider', props.className || '')} />
    )
  }
}

SidebarMenuItemTitle.propTypes = {
  className: PropTypes.string,
}

class SidebarMenuItemLabel extends Component {
  render() {

    let { url, className, labelClassName, faIcon, cssIcon, children, variant } = this.props

    let linkProps = {
      url: url,
      className: classNames('hidden-cn', className),
      faIcon: faIcon,
      linkClassName: classNames('nav-label', className ? className : ''),
    }

    if (!faIcon) {
      linkProps.cssIcon = classNames(
        'nav-icon',
        !cssIcon ? 'fa fa-circle' : cssIcon,
        variant ? `text-${variant}` : '',
        labelClassName ? labelClassName : '',
      )
    }

    return (
      <SidebarMenuItemLink {...linkProps} >
        {children}
      </SidebarMenuItemLink>
    )
  }
}

SidebarMenuItemLabel.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  faIcon: PropTypes.object,
  cssIcon: PropTypes.string,
  variant: PropTypes.object,
}

export default SidebarMenuItem
export {
  SidebarMenuItem,
  SidebarMenuItemDropdown,
  SidebarMenuItemTitle,
  SidebarMenuItemDivider,
  SidebarMenuItemLabel,
}