import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { Spinner } from '../Status/Spinner'

class SaveButton extends Component {

  render() {

    let saving = this.props.saving || false

    let props = {color:"primary", ...this.props}
    delete props.saving

    props.disabled = props.disabled || saving

    let contents = (() => {

      if (saving) {
        return (
          <React.Fragment>
            Saving...{' '}<Spinner size="1.0" ratio="0.15" color="#ffffff" />
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          <FontAwesomeIcon icon={faSave} />{' Save'}
        </React.Fragment>
      )
    })()

    return (
      <Button {...props}>
        {contents}
      </Button>
    )
  }
}

export { SaveButton }