import gql from "graphql-tag"
import { Connections } from '../fragments/Connections'
import { Settings } from '../fragments/Settings'

const AccountsQuery = gql`
query accounts {
  accounts {
    id
    name
    enabled
  }
}
`

const AccountQuery = gql`
  query account($accountID: ID!) {
    account(id: $accountID) {
      id
      name
      phone
      website
      enabled
      billingAddress {
        country
        postal
        province
        locality
        addressLines
      }
      primaryContact {
        name
        phone
        email
      }
      license {
        code
        name
        userLimit
        userLimitEnforced
      }
      userGroups {
        id
        name
        users {
          id
          name
        }
      }
      users {
        id
        name
        users {
          id
          name
          email
          enabled
          status
        }
        reportTemplates{
          enabled
          code
          name
          filter
          requiredObjects
          menu
          ranges {
            index
            length
            name
            required
            type
            rangeStart
            rangeEnd
          }
          options {
            index
            length
            name
            required
            type
            value
          }
          listItems {
            index
            type
          }
        }
        ...UserGroupConnections
        ...UserGroupSettings
      }
      admins {
        id
        name
        email
        enabled
        status
      }
    }
  }
  ${Connections("UserGroup")}
  ${Settings("UserGroup")}
`

export { AccountsQuery, AccountQuery }