import React, { Component } from 'react'
import {
    Col,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'

import { Auth } from 'aws-amplify'

import { SaveButton } from '../Buttons/SaveButton'
import { ResetButton } from '../Buttons/ResetButton'
import { ErrorAlert } from '../Status/Error'

const SPECIAL_CHARACTER_REGEX = /[~`!#$%^&*+=()+=_\-[\]\\';,/{}|\\":<>?]/g
const NUMBER_REGEX = /\d/

function containsNumber(string) {
  var hasNumber = NUMBER_REGEX
  return hasNumber.test(string)
}

function containsUpperCase(string) {
  var i = 0
  while(i <= string.length) {
    var character = string.charAt(i)
    if (!isNaN(character * 1)){
    } else if (SPECIAL_CHARACTER_REGEX.test(character)) {
    } else {
      if (character === character.toUpperCase()) {
        return true
      }
    }
    i++
  }
  return false
}

function containsLowerCase(string) {
  var i = 0
  while(i <= string.length) {
    var character = string.charAt(i)
    if (!isNaN(character * 1)){
    } else if (SPECIAL_CHARACTER_REGEX.test(character)) {
    } else {
      if (character === character.toLowerCase()) {
        return true
      }
    }
    i++
  }
  return false
}

function validatePassword(newPassword, confirmNewPassword) {
  let passwordValidation = {
    invalid: true,
    text: "",
  }
  if (newPassword !== confirmNewPassword) {
    passwordValidation.invalid = true
    passwordValidation.text = "Passwords don't match"
  } else if (newPassword.length< 8) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs minimum 8 characters"
  } else if (!containsNumber(newPassword)) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs a number"
  } else if (!(containsLowerCase(newPassword) && containsUpperCase(newPassword))) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs an upper and lower case value"
  } else {
    passwordValidation.invalid = false
    passwordValidation.text = ""
  }

  return passwordValidation
}

class ChangePasswordModal extends Component {
    constructor(props) {
      super(props)
  
      this.state = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        isSaving: false,
        isChanged: true,
        error: undefined
      }
  
      this.handleChange = this.handleChange.bind(this)
      this.reset = this.reset.bind(this)
      this.save = this.save.bind(this)
      this.doneSaving = this.doneSaving.bind(this)
      this.dismissError = this.dismissError.bind(this)
    }
  
    handleChange(e) {
        let state = this.state
        if (e.target.id === 'currentPassword') {
            state.currentPassword = e.target.value
        } else if (e.target.id === 'newPassword') {
            state.newPassword = e.target.value
        } else if (e.target.id === 'confirmNewPassword') {
            state.confirmNewPassword = e.target.value
        }
      this.setState({
        currentPassword: state.currentPassword,
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
        isSaving: false,
        isChanged: true
      })
    }


  
    async save() {
        let state = this.state
        
        let passwordValidation = validatePassword(state.newPassword, state.confirmNewPassword)
        if (!passwordValidation.invalid) {
          try {
            let user = await Auth.currentAuthenticatedUser()
            let result = await Auth.changePassword(user, state.currentPassword, state.newPassword)
            this.doneSaving(result)
          } catch (error) {
            let result = {
              error
            }
            this.doneSaving(result)
          }
        } else {
          let result = {
            error: {
              message: passwordValidation.text
            }
          }
          this.doneSaving(result)
        }
    }
  
    doneSaving(result){
      if (result.error) {
        this.setState({
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            isSaving: false,
            isChanged: true,
            error: result.error,
        })
        return
      }
  
      this.setState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        isSaving: false,
        isChanged: true,
        error: undefined,
      })

      this.props.toggle()
    }
  
    reset() {
      this.setState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        isSaving: false,
        isChanged: false,
      })
    }

    dismissError() {
      this.setState({
        error: undefined,
      })
    }
  
    render() {
  
      let props = this.props
      let state = this.state
  
      return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} backdrop={true} centered>
          <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
          <ModalBody>
            <FormGroup row>
                <Col>
                  <Label htmlFor="currentPassword">Current Password</Label>
                  <Input type="password" id="currentPassword" value={state.currentPassword} onChange={this.handleChange}/>
                </Col>
                </FormGroup>
                <FormGroup row>
                <Col>
                  <Label htmlFor="newPassword">New Password</Label>
                  <Input type="password" id="newPassword" value={state.newPassword} onChange={this.handleChange}/>
                </Col>
                </FormGroup>
                <FormGroup row>
                <Col>
                  <Label htmlFor="confirmNewPassword">Confirm New Password</Label>
                  <Input type="password" id="confirmNewPassword" value={state.confirmNewPassword} onChange={this.handleChange}/>
                </Col>
              </FormGroup>
          </ModalBody>
            <ModalFooter>
              <SaveButton onClick={this.save} disabled={!this.state.isChanged} saving={this.state.isSaving} />{' '}
              <ResetButton onClick={this.reset} disabled={!this.state.isChanged || this.state.isSaving} />
            </ModalFooter>
          <ErrorAlert error={state.error} onDismiss={this.dismissError} />
        </Modal>
      )
    }
  }

export { ChangePasswordModal }