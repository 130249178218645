import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  staticContext: PropTypes.any,
};

const defaultProps = {
  navconfig: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: { variant: 'info', text: 'NEW' }
      }]
  },
  isOpen: false
};

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  render() {
    const { className, children, ...attributes } = this.props;

    delete attributes.isOpen
    delete attributes.staticContext

    const navClasses = classNames(className, 'sidebar-nav');

    // sidebar-nav root
    return (
      <PerfectScrollbar className={navClasses} {...attributes} options={{ suppressScrollX: true }} >
        <Nav>
          {this.props.children}
        </Nav>
      </PerfectScrollbar>
    );
  }
}

SidebarMenu.propTypes = propTypes
SidebarMenu.defaultProps = defaultProps

export default SidebarMenu
export { SidebarMenu }