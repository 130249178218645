
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

function getSize(props, dontAddEm) {
  let size = Number.parseFloat(props.size || 1).toFixed(2)

  if (dontAddEm) {
    return size
  }

  return size + "em"
}

function borderSize(props) {
  
  let size = getSize(props, true)
  let ratio = Number.parseFloat(props.ratio) || 0.1

  return (size * ratio).toFixed(2) + "em"
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
  } : null
}

function borderColor(props) {
  let color = props.color || defaultColor
  let rgb = hexToRgb(color)
  return 'rgba(' + [rgb.r, rgb.g, rgb.b, 0.2].join(',') + ')'
}

let defaultColor = '#727981'

const Spinner = styled.div`
  border: ${props => borderSize(props) } solid ${props => borderColor(props) };
  border-top: ${props => borderSize(props) } solid ${props => props.color || defaultColor };
  border-radius: 50%;
  width: ${props => getSize(props) };
  height: ${props => getSize(props) };
  animation: ${spin} 1s linear infinite;
  display: inline-block;
`

export { Spinner }