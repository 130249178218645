import React, { Component } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import config from '../../config'

class UnauthorizedCard extends Component {

  render() {

    let brandName = config.app.brandName || ""
    let startsWithVowel = [ "a", "e", "i", "o", "u" ].includes(brandName.charAt(0).toLowerCase())

    return (
      <Card>
        <CardHeader>
          Welcome to {brandName}
        </CardHeader>
        <CardBody>
          <p>Thank you for being a{ startsWithVowel ? "n" : ""} {brandName} customer.</p>
          <p>You do not currently have administrator access, which is required for using the {brandName} console.</p>
          <p>To receive access, please contact your account administrator.</p>
        </CardBody>
      </Card>
    )
  }
}

class Unauthorized extends Component {

  render() {

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <UnauthorizedCard/>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Unauthorized
export { Unauthorized }