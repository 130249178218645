import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import AppSidebarToggler from '../../components/CoreUI/AppSidebarToggler'
import { ChangePasswordModal } from '../../components/Modals/ChangePasswordModals'

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      changePasswordModal: false,
    }
    this.changePassword = this.changePassword.bind(this)
  }
  changePassword() {
    this.setState({
      changePasswordModal: !this.state.changePasswordModal
    })
  }

  render() {
    let props = this.props
    let state = this.state

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="#"> Welcome {this.props.props.userId}</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#">
              <div onClick={this.changePassword}>Change Password</div>
            </NavLink>

          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#">
              <div onClick={this.props.props.handleLogout}>Sign Out</div>
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          { 
            process.env.NODE_ENV === "production" ? null :
              <span style={{ paddingRight: '2rem' }}>
                <b className="text-light bg-dark">{ process.env.NODE_ENV.toUpperCase() }</b>
              </span>
          }
        </Nav>
        <ChangePasswordModal {...props} isOpen={state.changePasswordModal} toggle={this.changePassword}/>
      </React.Fragment>
    );
  }
}

export default DefaultHeader;
