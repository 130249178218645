import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class BreadcrumbContainer extends Component {

  findRouteName(url) {

    if (!this.props.routes) {
      return null
    }

    let route = this.props.routes.find(function (route) {
      return route.path === url
    })

    if (route && route.name) {
      return route.name
    }

    return null
  }

  render() {

    let match = this.props.match
    if (!match) {
      return null
    }

    let routeName = this.findRouteName(match.url)
    if (!routeName) {
      return null
    }

    if (match.isExact) {
      return <BreadcrumbItem active>{routeName}</BreadcrumbItem>  
    }

    return <BreadcrumbItem><Link to={match.url}>{routeName}</Link></BreadcrumbItem>
  }
}

class BreadcrumbsContainer extends Component {

  getPaths(pathname) {
    var paths = ['/'];
  
    if (pathname === '/') {
      return paths
    }
  
    pathname.split('/').reduce(function (prev, curr) {
      var currPath = prev + '/' + curr
      paths.push(currPath)
      return currPath
    })

    return paths
  }
    
  render() {

    let url = this.props.url

    if (!url && this.props.location.pathname) {
      url = this.props.location.pathname
    }

    if (!url) {
      return null
    }

    let paths = this.getPaths(url)
    let routes = this.props.routes

    return (
      <Breadcrumb>
      {
        paths.map(function (path, i) {
          return (
            <Route key={i.toString()} path={path} render={props => {
              return <BreadcrumbContainer routes={routes} {...props} />
            }}/>
          )
        })
      }
      </Breadcrumb>
    )
  }
}

class Breadcrumbs extends Component {

  render() {

    let routes = this.props.routes

    return (
        <Route path="/" render={props => {
          return <BreadcrumbsContainer routes={routes} {...props} />
        }}/>
    )
  }
}

export default Breadcrumbs
export { Breadcrumbs }