import React, { Component } from 'react'
import { ViewerContext } from './ViewerProvider'
import { Redirect } from 'react-router-dom'
import { PropTypes } from 'prop-types'

class SystemAdmin extends Component {

  render() {

    let failCase = null

    if (this.props.redirectTo) {
      failCase = <Redirect to={this.props.redirectTo} />
    }

    return(
      <ViewerContext.Consumer>
        { viewer => viewer.amSystemAdmin ? this.props.children : failCase }
      </ViewerContext.Consumer>
    )
  }
}

SystemAdmin.propTypes = {
  redirectTo: PropTypes.string,
}

class NotSystemAdmin extends Component {

  render() {

    let failCase = null

    if (this.props.redirectTo) {
      failCase = <Redirect to={this.props.redirectTo} />
    }

    return(
      <ViewerContext.Consumer>
        { viewer => viewer.amSystemAdmin ? failCase : this.props.children }
      </ViewerContext.Consumer>
    )
  }
}

NotSystemAdmin.propTypes = {
  redirectTo: PropTypes.string,
}

export { SystemAdmin, NotSystemAdmin }

