import config from "../config"
import { API, Auth } from "aws-amplify"
import { HttpLink } from "apollo-link-http"
import { onError } from "apollo-link-error";

class PortalAPIClass {

  fetch = (options) => {
    return Auth.currentSession().then(response => {
      let apiName = config.graphql.NAME
      let path = config.graphql.PATH

      const body = typeof options.body === `object` ? options.body : JSON.parse(options.body)

      let myInit = {
        headers: {
          "Portal-IDToken": response.idToken.jwtToken,
          "OS" : "web",
        },
        body: body,
        response: true
      }
      
      return API.post(apiName, path, myInit)
  
    }).then(awsResponse => {
      // Convert the response
      const responseBody = typeof awsResponse.data === `object` ? JSON.stringify(awsResponse.data) : awsResponse.data;
  
      const headers = new Headers();
      Object.entries(awsResponse.headers).forEach(function ([key, value]) {
        headers.append(key, value);
      });
  
      return new Response(responseBody, {
        status: awsResponse.status,
        statusText: awsResponse.statusText,
        headers
      });
  
    }).catch(error => {
        return new Response(`{"errors":[{"message":"${error}"}]}`)
    });
  };

  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
  
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    } 
  });

  httpLink = new HttpLink({
    uri: config.apiGateway.URL,
    fetch: (uri, options) => {
      return this.fetch(options)
    },
  })
}

export const PortalAPI = new PortalAPIClass()
export default PortalAPI