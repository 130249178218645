  const ConnectionCredentialsType = {
    proxy: "PROXY",
    managedtoken: "MANAGEDTOKEN",
    basic: "BASIC",
  }

  const ConnectionType = {
    proxy: "PROXY",
    rest: "REST",
    ejs: "EJS",
  }

  const ReportTemplateFieldListItemType = {
    order: "ORDER",
  }

  const ReportTemplateFieldType = {
    alphanumeric: "ALPHANUMERIC",
    boolean: "BOOLEAN",
    binnumber: "BINNUMBER",
    customernumber: "CUSTOMERNUMBER",
    date: "DATE",
    float: "FLOAT",
    integer: "INTEGER",
    periodmmyy: "PERIODMMYY",
    warehouse: "WAREHOUSE",
    year: "YEAR",
  }

  const ReportTemplateMenu = {
    customer: "CUSTOMER",
    order: "ORDER",
  }

  const ReportTemplateRequiredObject = {
    customer: "CUSTOMER",
    order: "ORDER",
    warehouse: "WAREHOUSE",
  }

  const SalesRepCodeType = {
    inside: "INSIDE",
    outside: "OUTSIDE",
  }

  const SettingType = {
    string: "STRING",
    boolean: "BOOLEAN",
    int: "INT",
    float: "FLOAT",
    arrayofstrings: "ARRAYOFSTRINGS",
    arrayofsalesrepcodes: "ARRAYOFSALESREPCODES",
    salesrep: "SALESREP",
  }

  const UserFieldValueType = {
    boolean: "boolean",
    date: "date",
    double: "double",
    string: "string",
  }
  

  const UserStatus = {
    inviteNotSent: "INVITE_NOT_SENT",
    inviteSent: "INVITE_SENT",
    confirmed: "CONFIRMED",
  }

  const DayCountUnitType = {
    businessDays: "b",
    calendarDays: "c",
  }  

  const SettingValueSubType = {
    dayCount: "dayCount",
    userFields: "userFields",
  }
  

export { ConnectionCredentialsType, ConnectionType, DayCountUnitType, ReportTemplateFieldListItemType, ReportTemplateFieldType, ReportTemplateMenu, ReportTemplateRequiredObject, SalesRepCodeType, SettingType, SettingValueSubType, UserFieldValueType, UserStatus }