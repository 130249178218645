import DefaultLayout from './containers/DefaultLayout';
import { createLoadable } from './utility/utility'

const Accounts = createLoadable(() => import('./views/Accounts'))
const Account = createLoadable(() => import('./views/Accounts/Account/Account'))
const PortalGraphiQL = createLoadable(() => import('./views/PortalGraphiQL'))

const accountsPath = '/accounts'

const newAccountPath = '/accounts/new'
const accountPath = '/accounts/:accountID'
const accountTabPath = accountPath + "/:activeTab"

function accountPathForAccount(accountID) {
  return '/accounts/' + accountID
}

const connectionPath = accountPath + '/connections/:connectionID'
const connectionTabPath = connectionPath + "/:activeTab"

const userGroupsPath = accountPath + '/groups'

function userGroupsPathForAccount(accountID) {
  return accountPathForAccount(accountID) + '/groups'
}

function userGroupPathForUserGroup(accountID, groupID) {
  return userGroupsPathForAccount(accountID) + '/' + groupID
}

const userGroupPath = userGroupsPath + '/:groupID'
const userGroupTabPath = userGroupPath + "/:activeTab"

function userGroupConnectionsPathForUserGroup(accountID, groupID) {
  return userGroupPathForUserGroup(accountID, groupID) + '/connections'
}

function connectionPathForConnection(accountID, connectionID) {
  return accountPathForAccount(accountID) + '/connections/' + connectionID
}

const connectionsPath = accountPath + '/connections'

function connectionsPathForAccount(accountID) {
  return accountPathForAccount(accountID) + '/connections'
}

const userGroupConnectionsPath = userGroupPath + '/connections'
const userGroupConnectionPath = userGroupConnectionsPath + '/:connectionID'
const userGroupConnectionTabPath = userGroupConnectionPath + "/:activeTab"

function userGroupConnectionPathForUserGroupConnection(accountID, groupID, connectionID) {
  return userGroupConnectionsPathForUserGroup(accountID, groupID) +'/' + connectionID
}

const usersPath = accountPath + '/users'

function usersPathForAccount(accountID) {
  return accountPathForAccount(accountID) + '/users'
}

const userPath = usersPath + '/:userID'
const userTabPath = userPath + "/:activeTab"

function userPathForUser(accountID, userID) {
  return usersPathForAccount(accountID) + '/' + userID
}

const userConnectionsPath = userPath + '/connections'
const userConnectionPath = userConnectionsPath + '/:connectionID'
const userConnectionTabPath = userConnectionPath + "/:activeTab"

function userConnectionsPathForUser(accountID, userID) {
  return userPathForUser(accountID, userID) + '/connections'
}

function userConnectionPathForUserConnection(accountID, userID, connectionID) {
  return userPathForUser(accountID, userID) + '/connections/' + connectionID
}

const reportTemplatesPath = accountPath + '/reporttemplates'
const reportTemplatePath = reportTemplatesPath + '/:reportCode'
const reportTemplateTabPath = reportTemplatePath + "/:activeTab"

function reportTemplatesPathForAccount(accountID) {
  return accountPathForAccount(accountID) + '/reporttemplates'
}

function reportTemplatePathForAccount(accountID, reportTemplateCode) {
  return reportTemplatesPathForAccount(accountID) + '/' + reportTemplateCode
}

function reportTemplatePathForReportTemplate(accountID, userGroupID, reportTemplateCode) {
  return userGroupPathForUserGroup(accountID, userGroupID) + '/reporttemplates/' + reportTemplateCode
}
const userGroupReportTemplates = userGroupPath + '/reporttemplates'
const userGroupReportTemplatePath = userGroupReportTemplates + '/:reportCode'
const userGroupReportTemplateTabPath = userGroupReportTemplatePath + '/:activeTab'

const accountReportTemplates = accountPath + '/reporttemplates'
const accountReportTemplatePath = accountReportTemplates + '/:reportCode'
const accountReportTemplateTabPath = accountReportTemplatePath + '/:activeTab'

function userGroupReportTemplatesPathForUserGroup(accountID, groupID) {
  return userGroupPathForUserGroup(accountID, groupID) + '/reporttemplates'
}

function userGroupReportTemplatePathForUserGroupReportTemplate(accountID, userGroupID, reportTemplateCode) {
  return userGroupReportTemplatesPathForUserGroup(accountID, userGroupID) + '/' + reportTemplateCode
}

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: accountsPath, exact: true, name: 'Accounts', component: Accounts },
  { path: accountPath, exact: false, name: 'Account', component: Account },
  { path: '/graphiql', exact: true, name: 'GraphiQL', component: PortalGraphiQL },
];

export default routes
export {
  routes,
  createLoadable,
  newAccountPath,
  accountPath,
  accountsPath,
  accountPathForAccount,
  accountTabPath,
  connectionPath,
  connectionTabPath,
  connectionPathForConnection,
  connectionsPath,
  connectionsPathForAccount,
  userGroupPath,
  userGroupTabPath,
  userGroupPathForUserGroup,
  userGroupConnectionsPathForUserGroup,
  userGroupsPath,
  userGroupsPathForAccount,
  userGroupConnectionPath,
  userGroupConnectionPathForUserGroupConnection,
  userGroupConnectionsPath,
  userGroupConnectionTabPath,
  userConnectionPathForUserConnection,
  userConnectionPath,
  userConnectionTabPath,
  userConnectionsPath,
  userConnectionsPathForUser,
  userPath,
  userTabPath,
  userPathForUser,
  usersPath,
  usersPathForAccount,
  reportTemplatesPathForAccount,
  reportTemplatePath,
  reportTemplateTabPath,
  userGroupReportTemplates,
  reportTemplatePathForReportTemplate,
  userGroupReportTemplatePathForUserGroupReportTemplate,
  userGroupReportTemplateTabPath,
  userGroupReportTemplatePath,
  userGroupReportTemplatesPathForUserGroup,
  reportTemplatePathForAccount,
  accountReportTemplates,
  accountReportTemplatePath,
  accountReportTemplateTabPath
}