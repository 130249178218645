import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import Amplify from "aws-amplify"
import config from "./config"

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.proxyAPI.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: config.graphql.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

ReactDOM.render(<App />, document.getElementById('root'))
unregisterServiceWorker()
