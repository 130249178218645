import React, { Component, Fragment } from 'react'
import { Carousel,  CarouselItem } from 'reactstrap'
import { PropTypes } from 'prop-types'
import { Auth } from "aws-amplify"

import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, FormFeedback, Row } from 'reactstrap'
import { ErrorAlert } from "../../../components/Status/Error"
import config from "../../../config"

const LOGIN_PAGE_COUNT = 4

const SPECIAL_CHARACTER_REGEX = /[~`!#$%^&*+=()+=_\-[\]\\';,/{}|\\":<>?]/g
const NUMBER_REGEX = /\d/
const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

function validateEmail(email) {
  let emailValidation = {
    invalid: true,
    text: "",
  }

  if (!email.match(EMAIL_REGEX)) {
    emailValidation.invalid = true
    emailValidation.text = "Invalid Email"
  } else {
    emailValidation.invalid = false
    emailValidation.text = ""
  }
  return emailValidation
}

function validatePassword(password) {
  let passwordValidation = {
    invalid: true,
    text: "",
  }

  if (password.length< 8) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs minimum 8 characters"
  } else if (!containsNumber(password)) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs a number"
  } else if (!(containsLowerCase(password) && containsUpperCase(password))) {
    passwordValidation.invalid = true
    passwordValidation.text = "Password needs an upper and lower case value"
  } else {
    passwordValidation.invalid = false
    passwordValidation.text = ""
  }

  return passwordValidation
}

function validateCode(code) {
  return code.length > 0
}

function containsNumber(string) {
  var hasNumber = NUMBER_REGEX
  return hasNumber.test(string)
}

function containsUpperCase(string) {
  var i = 0
  while(i <= string.length) {
    var character = string.charAt(i)
    if (!isNaN(character * 1)){
    } else if (SPECIAL_CHARACTER_REGEX.test(character)) {
    } else {
      if (character === character.toUpperCase()) {
        return true
      }
    }
    i++
  }
  return false
}

function containsLowerCase(string) {
  var i = 0
  while(i <= string.length) {
    var character = string.charAt(i)
    if (!isNaN(character * 1)){
    } else if (SPECIAL_CHARACTER_REGEX.test(character)) {
    } else {
      if (character === character.toLowerCase()) {
        return true
      }
    }
    i++
  }
  return false
}

class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      passwordInvalid: false,
      passwordInvalidText: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    if (event.target.id === 'newPassword') {
      let passwordValidation = validatePassword(event.target.value)
      this.setState({
        newPassword: event.target.value,
        passwordInvalid: passwordValidation.invalid,
        passwordInvalidText: passwordValidation.text
      })
    }
  }

  async handleSubmit() {  
    this.props.dismissError()

    try {

      let user = await Auth.completeNewPassword(this.props.user, this.state.newPassword)
      this.props.handleLogin(user.signInUserSession.idToken.payload.name)
    
    } catch(error) {
      this.props.handleError(error)
    }
  }

  render() {

    let disableSubmit = this.state.passwordInvalid

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{config.app.name} - Create New Password</h1>
                    <p className="text-muted">Please enter your new password</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="newPassword" type="password" placeholder="Password" value={this.state.newPassword} invalid={this.state.passwordInvalid} onChange={this.handleChange} />
                      <FormFeedback>{this.state.passwordInvalidText}</FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="link" className="px-0" onClick={this.props.next}>Back</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="primary" className="px-4" disabled={disableSubmit} onClick={this.handleSubmit}>Send</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

class VerificationCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      code: "",
      password: "",
      passwordInvalid: false,
      passwordInvalidText: "",
      emailInvalid: false,
      emailInvalidText: ""
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    if (event.target.id === "password") {
      let passwordValidation = validatePassword(event.target.value)

      this.setState({
        password: event.target.value,
        passwordInvalid: passwordValidation.invalid,
        passwordInvalidText: passwordValidation.text
      })
    } else if (event.target.id === "email") {
      let emailValidation = validateEmail(event.target.value)

      this.setState({
        [event.target.id]: event.target.value.toLowerCase(),
        emailInvalid: emailValidation.invalid,
        emailInvalidText: emailValidation.text
      })
    } else {
      this.setState({
        [event.target.id]: event.target.value
      })
    }
  }

  async handleSubmit() {
    
    this.props.dismissError()

    try {

      await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
      
      let user = await Auth.signIn(this.state.email, this.state.password)
      this.props.handleLogin(user.signInUserSession.idToken.payload.name);          

    } catch(error) {
      this.props.handleError(error)
    }
  }

  render() {

    let disableSubmit = !validateCode(this.state.code) || this.state.passwordInvalid || this.state.emailInvalid
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{config.app.name} - Verification Code</h1>
                    <p className="text-muted">Please enter your Verification Code</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email" type="email" placeholder="Email" value={this.state.email} invalid={this.state.emailInvalid} onChange={this.handleChange} />
                      <FormFeedback>{this.state.emailInvalidText}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="code" type="text" placeholder="Verification Code" value={this.state.code} onChange={this.handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="password" type="password" placeholder="New Password" value={this.state.password} invalid={this.state.passwordInvalid} onChange={this.handleChange} />
                      <FormFeedback>{this.state.passwordInvalidText}</FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="link" className="px-0" onClick={this.props.previous}>Back</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="primary" className="px-4" disabled={disableSubmit} onClick={this.handleSubmit}>Verify</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forgotPasswordEmail: "",
      emailInvalid: false,
      emailInvalidText: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    let emailValidation = validateEmail(event.target.value)

    this.setState({
      [event.target.id]: event.target.value.toLowerCase(),
      emailInvalid: emailValidation.invalid,
      emailInvalidText: emailValidation.text
    })
  }

  async handleSubmit() {
    this.props.dismissError()

    try {
      
      await Auth.forgotPassword(this.state.forgotPasswordEmail)
      this.props.next()

    } catch(error) {
        this.props.handleError(error)
    }
  }

  render() {

    let disableSubmit = this.state.emailInvalid

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{config.app.name} - Forgot Password</h1>
                    <p className="text-muted">Please enter your email</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="forgotPasswordEmail" type="email" placeholder="Email" value={this.state.forgotPasswordEmail} invalid={this.state.emailInvalid} onChange={this.handleChange} />
                      <FormFeedback>{this.state.emailInvalidText}</FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="link" className="px-0" onClick={this.props.previous}>Back</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="primary" className="px-4" disabled={disableSubmit} onClick={this.handleSubmit}>Send</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loginEmail: "",
      loginPassword: "",
      emailInvalid: false,
      emailInvalidText: ""
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    if (event.target.id === 'loginEmail') {
      let emailValidation = validateEmail(event.target.value)

      this.setState({
        [event.target.id]: event.target.value.toLowerCase(),
        emailInvalid: emailValidation.invalid,
        emailInvalidText: emailValidation.text
      })
    } else {
      this.setState({
        [event.target.id]: event.target.value
      })
    }
  }

  async handleSubmit() {
    this.props.dismissError()

    try {

      let user = await Auth.signIn(this.state.loginEmail, this.state.loginPassword)

      if (user.challengeName === ("NEW_PASSWORD_REQUIRED" || "RESET_REQUIRED")) {
        this.props.setUser(user)
      } else {
        this.props.handleLogin(user.signInUserSession.idToken.payload.name);          
      }

    } catch(error) {

      if (error.code === 'PasswordResetRequiredException') {
        this.props.next()
      } else {
        this.props.handleError(error)
      }
    }
  }

  render() {

    let disableSubmit = this.state.emailInvalid

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{config.app.name}</h1>
                    <p className="text-muted">Sign in to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="loginEmail" type="email" placeholder="Email" value={this.state.loginEmail} invalid={this.state.emailInvalid} onChange={this.handleChange} />
                      <FormFeedback>{this.state.emailInvalidText}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="loginPassword" type="password" placeholder="Password" value={this.state.loginPassword} onChange={this.handleChange} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" disabled={disableSubmit} onClick={this.handleSubmit}>Login</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="link" className="px-0" onClick={this.props.next}>Forgot password?</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

class LoginPages extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      activeIndex: 0,
      user: null,
      showError: false,
      errorMessage: "",
     }

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.setUser = this.setUser.bind(this)
    this.handleError = this.handleError.bind(this)
    this.dismissError = this.dismissError.bind(this)
  }

  componentDidMount() {
    this.checkUserAuthentication()
  }

  async checkUserAuthentication() {
    try {
      await Auth.currentCredentials()
      let user =  await Auth.currentSession()
      this.props.handleLogin(user.idToken.payload.name)

    } catch (error) {
      
      console.error(error)
      
      await Auth.signOut()

      this.props.handleLogout()
    }
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) {
      return
    }
    let nextIndex = this.state.activeIndex === LOGIN_PAGE_COUNT - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex, showError: false })
  }

  previous() {
    if (this.animating) {
      return
    }

    let nextIndex = this.state.activeIndex === 0 ? LOGIN_PAGE_COUNT- 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex, showError: false })
  }

  setUser(newUser) {
    if (this.animating) {
      return
    }
    const nextIndex = 3 
    this.setState({ 
      activeIndex: nextIndex, 
      user : newUser,
    })
  }

  handleError(error) {
    this.setState({
      error: error,
    })
  }

  dismissError(error) {
    this.setState({
      error: undefined,
    })
  }

  render() {

    let commonProps = {
      next: this.next,
      previous: this.previous,
      handleError: this.handleError,
      dismissError: this.dismissError,
    }

    let loginPages = [
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={"Login"}>
        <Login {...commonProps} newUser={this.newUser} setUser={this.setUser} {...this.props}/>
      </CarouselItem>, 
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={"ForgotPassword"}>
        <ForgotPassword {...commonProps} />
      </CarouselItem>, 
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={"VerificationCode"}>
        <VerificationCode {...commonProps} {...this.props}/>
      </CarouselItem>,
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={"NewUser"}>
        <NewUser {...commonProps} user={this.state.user} {...this.props}/>
      </CarouselItem>,
      ]

    let activeIndex  = this.state.activeIndex

    return (
      <Fragment>
          <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel" interval={false} keyboard={false}>
            {loginPages}
          </Carousel>
          <ErrorAlert error={this.state.error} onDismiss={this.dismissError} />
      </Fragment>
    )
  }
}

LoginPages.propTypes = {
  handleLogout: PropTypes.func.isRequired
}

export default LoginPages
export { 
  containsNumber, 
  containsLowerCase, 
  containsUpperCase, 
  validateEmail, 
  validatePassword, 
  Login, 
  ForgotPassword, 
  VerificationCode,
  NewUser
}