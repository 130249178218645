import { Settings } from './Settings'

export function ConnectionContents(excludeSettingsFragment){
  return `
  fragment ConnectionContents on Connection {
    id
    name
    url
    fetchWhereUrl
    type
    credentials {
      type
    }
    enabled {
      valueBoolean
      userGroup {
        id
        name
      }
    }
    enabledInherited {
      valueBoolean
      userGroup {
        id
        name
      }
    }
    ...ConnectionSettings
  }
  ${ excludeSettingsFragment ? '' : Settings("Connection") }
`
}

export function Connections(parent) {
  return `
    fragment ${parent}Connections on ${parent} {
      connections {
        ...ConnectionContents
      }
    }
    ${ConnectionContents(true)}
    ${Settings("Connection")}
  `
}