import React, { Component } from 'react'
import { ViewerContext } from './ViewerProvider'
import { PropTypes } from 'prop-types'

class ViewerLoading extends Component {

  render() {

    let props = this.props

    if (!props.loading) {
      return props.children
    }

    return(
      <ViewerContext.Consumer>
        { viewer => viewer.loading ? props.loading : this.props.children }
      </ViewerContext.Consumer>
    )
  }
}

ViewerLoading.propTypes = {
  loading: PropTypes.element,
}

export { ViewerLoading }

