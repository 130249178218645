import { ConnectionType, DayCountUnitType, SalesRepCodeType, SettingType, SettingValueSubType, UserFieldValueType, } from './enums'
import { slice } from 'lodash'

const customLicenseTypePresetObject = {
  code: "custom",
  name: "Custom",
  userLimit: { enforced: true, editable: true, default: 1 },
}

const LicenseTypePresetObjects = [
  { code: "perpetual", name: "License & Maintenance", userLimit: { enforced: true, editable: true, default: 1 } },
  { code: "unlimited", name: "Unlimited", userLimit: { enforced: false, editable: false, default: 0 } },
  { code: "free", name: "Free Tier", userLimit: { enforced: true, editable: false, default: 1 } },
  { code: "subscription", name: "Subscription", userLimit: { enforced: false, editable: false, default: 0 } },
  customLicenseTypePresetObject,
]

const licenseTypePresetObjectForCode = code => {
  let license = LicenseTypePresetObjects.find(type => {
    return type.code === code
  })
  return license || customLicenseTypePresetObject
}

const ConnectionTypeNames = {
  proxy: "CloudSuite",
  rest: "Direct ION API",
  ejs: "Expertek API Service",
}

const ConnectionTypeObjects = (() => {
  let types = []
  for (let type in ConnectionType) {
    types.push({
      code: ConnectionType[type],
      name: ConnectionTypeNames[type],
    })
  }
  return types
}
)()

const connectionTypeObjectForCode = code => {
  return ConnectionTypeObjects.find(type => {
    return type.code === code
  })
}

const SalesRepCodeTypeNames = {
  inside: "Inside",
  outside: "Outside",
}

const SalesRepCodeTypeObjects = (() => {
  let types = []
  for (let type in SalesRepCodeType) {
    types.push({
      code: SalesRepCodeType[type],
      name: SalesRepCodeTypeNames[type],
    })
  }
  return types
}
)()

const SalesRepCodeTypeObjectForCode = code => {
  return SalesRepCodeTypeObjects.find(type => {
    return type.code === code
  })
}

const DayCountUnitTypeNames = {
  businessDays: "Business days",
  calendarDays: "Calendar days",
}

const DayCountUnitTypeObjects = (() => {
  let types = []
  for (let type in DayCountUnitType) {
    types.push({
      code: DayCountUnitType[type],
      name: DayCountUnitTypeNames[type],
    })
  }
  return types
}
)()

const DayCountUnitTypeObjectForCode = code => {
  return DayCountUnitTypeObjects.find(type => {
    return type.code === code
  })
}

const UserFieldValueTypeNames = {
  boolean: "Yes/No",
  date: "Date",
  double: "Number",
  string: "Text",
}

const UserFieldValueTypeObjects = (() => {
  let types = []
  for (let type in UserFieldValueType) {
    types.push({
      code: UserFieldValueType[type],
      name: UserFieldValueTypeNames[type],
    })
  }
  return types
}
)()

const UserFieldValueTypeObjectForCode = code => {
  return UserFieldValueTypeObjects.find(type => {
    return type.code === code
  })
}

const SettingTypeNames = {
  bodKitAlwaysPriceOverrideEnabled: "bod-kit-always-price-override-enabled",
  cenposCredentials: "cenpos-credentials",
  chooseSalesRepCodeEnabled: "choose-sales-rep-code-enabled",
  chooseShipViaEnabled: "choose-ship-via-enabled",
  companyIDs: "company-ids",
  contactAddEnabled: "contact-add-enabled",
  contactEditEnabled: "contact-edit-enabled",
  consignmentStockStatusFilterDefaultOptions: "consignment-stock-status-filter-default-options",
  currencyDecimalPrecision: "currency-decimal-precision",
  customerAddEnabled: "customer-add-enabled",
  customerEditEnabled: "customer-edit-enabled",
  customerNotesEditingEnabled: "customer-notes-editing-enabled",
  customersAllowed: "customers-allowed",
  defaultOrderType: "default-order-type",
  defaultTakenBy: "default-taken-by",
  defaultWarehouseID: "default-warehouse-id",
  enableSelectOrderType: "enable-select-order-type",
  fieldUpdateAuthID: "field-update-auth-id",
  fieldUpdateAuthSecret: "field-update-auth-secret",
  fogoLogLevel: "fogo-log-level",
  hideBackorder: "hide-backorder",
  holdNewOrdersWithCode: "hold-new-orders-with-code",
  homePromotionURL: "home-promotion-url",
  incompleteCountBasedEntryWarningsEnabled: "incomplete-count-based-entry-warnings-enabled",
  ignoreEdiFlags: "ignore-edi-flags",
  invoicesByDueDateEnabled: "invoices-by-due-date-enabled",
  itemSearchProductLabelTemplate: "item-search-product-label-template",
  itemSearchDescription1LabelTemplate: "item-search-description1-label-template",
  itemSearchDescription2LabelTemplate: "item-search-description2-label-template",
  itemSearchExtendedDescriptionLabelTemplate: "item-search-extended-description-label-template",
  logoInNavigationBar: "logo-in-navigation-bar",
  nonStockProductsEnabled: "non-stock-products-enabled",
  orderCancelStageCutoff: "order-cancel-stage-cutoff",
  orderCounterSaleEnabled: "order-counter-sale-enabled",
  orderCounterSalesShipVia: "order-counter-sales-ship-via",
  orderCounterSalesWarehouse: "order-counter-sales-warehouse",
  orderDispositionEnabled: "order-disposition-enabled",
  orderEditEnabled: "order-edit-enabled",
  orderEditStageCutoff: "order-edit-stage-cutoff",
  orderEditStageCutoffWillCall: "order-edit-stage-cutoff-will-call",
  orderOriginCode: "oe-origin-code",
  orderFulfillmentEnabled: "order-fulfillment-enabled",
  orderFulfillmentDefaultValue: "order-fulfillment-default-value",
  orderMarginMinimum: "order-margin-minimum",
  orderMarginMinimumZeroRevenueMaximumLoss: "order-margin-minimum-zero-revenue-maximum-loss",
  orderSubmitEdiOverrideEnabled: "order-submit-edi-override-enabled",
  orderSubmitType: "order-submit-type",
  priceOverrideEnabled: "price-override-enabled",
  productCostType: "product-cost-type",
  productCostsIncludeAddonCost: "product-costs-include-addon-cost",
  productDocuments: "product-documents",
  productListsAddEnabled: "product-lists-add-enabled",
  productListsEditEnabled: "product-lists-edit-enabled",
  productListsEnabled: "product-lists-enabled",
  productListsShipToField: "product-lists-shipto-field",
  productListsFetchProductsEnabled: "product-lists-fetch-products-enabled",
  promiseDateRequired: "promise-date-required",
  quantityDecimalPrecision: "quantity-decimal-precision",
  quantityOnOrderEnabled: "quantity-on-order-enabled",
  quoteDefaultCancelDateDuration: "quote-default-cancel-date-duration",
  refreshProductsOnScrollEnabled: "refresh-products-on-scroll-enabled",
  rememberPasswordEnabled: "remember-password-enabled",
  reportPrintingEnabled: "report-printing-enabled",
  reportSubmitApiVersion: "report-submit-api-version",
  reportQueue: "report-queue",
  reportViewRelativePath: "report-view-relative-path",
  reportViewEnabled: "report-view-enabled",
  reportViewPrinter: "report-view-printer",
  requestedShipDateDefault: "requested-ship-date-default",
  requestedShipDateDefaultMethod: "requested-ship-date-default-method",
  requestedShipDateRequiresConfirmation: "requested-ship-date-requires-confirmation",
  requireFreightEnabled: "require-freight-enabled",
  restrictAddNewOrderEnabled: "restrict-add-new-order-enabled",
  routeEditEnabled: "route-edit-enabled",
  routeEditFormat: "route-edit-format",
  salesRepCodeFilterEnabled: "sales-rep-code-filter-enabled",
  salesRepCodes: "sales-rep-codes",
  salesRepCodePrimary: "sales-rep-code-primary",
  salesRepFilterDivision: "sales-rep-filter-divisions",
  salesRepFilterTerritory: "sales-rep-filter-territories",
  salesRepFilterVersion: "sales-rep-filter-version",
  shipDateRequired: "ship-date-required",
  shipOrderEnabled: "ship-order-enabled",
  shiptoAddEnabled: "shipto-add-enabled",
  shiptoEditEnabled: "shipto-edit-enabled",
  shiptoEditTaxCodesEnabled: "shipto-edit-tax-codes-enabled",
  shiptoEditTermsEnabled: "shipto-edit-terms-enabled",
  shoppingListContractItems: "shopping-list-contract-items",
  showDiscount: "show-discount",
  showInventoryQuantities: "show-inventory-quantities",
  signatureCaptureEnabled: "signature-capture-enabled",
  signatureCaptureDownloadRelativePath: "signature-capture-download-relative-path",
  signatureCaptureUploadRelativePath: "signature-capture-upload-relative-path",
  sxapiConnectionString: "sxapi-connection-string",
  sxapiICGetwhseproductdatageneralv2Available: "sxapi-icgetwhseproductdatageneralv2-available",
  sxapiProductCrossReferenceListVersion: "sxapi-product-cross-reference-list-version",
  sxapiProductCrossReferenceVersion: "sxapi-product-cross-reference-version",
  sxapiProductDataGeneralVersion: "sxapi-product-data-general-version",
  sxapiRecordLimit: "sxapi-record-limit",
  sxapiUseUserToken: "sxapi-use-user-token",
  sxapiWarehouseDataGeneralVersion: "sxapi-warehouse-data-general-version",
  sxeOperatorID: "sxe-operator-id",
  sxeOperatorPassword: "sxe-operator-password",
  tenderByAllPaymentTypesEnabled: "tender-by-all-payment-types-enabled",
  tenderByCreditCardEnabled: "tender-by-credit-card-enabled",
  tenderByCreditCardMethod: "tender-credit-card-method",
  tenderByCreditCardNonRefundable: "tender-by-credit-card-non-refundable",
  tenderByCreditCardTransCodeEnabled: "tender-credit-card-trans-code-enabled",
  tenderByCreditCardTransCodeType: "tender-by-credit-card-trans-code-type",
  tenderMethodOnFullBackorderByShipped: "tender-method-on-full-backorder-by-shipped",
  tenderOnEditOrderEnabled: "tender-on-edit-order-enabled",
  tenderPaymentTypeAmericanExpress: "tender-payment-type-american-express",
  tenderPaymentTypeCheckCodes: "tender-payment-type-check-codes",
  tenderPaymentTypeDiscover: "tender-payment-type-discover",
  tenderPaymentTypeMastercard: "tender-payment-type-mastercard",
  tenderPaymentTypeVisa: "tender-payment-type-visa",
  termsOverrideEnabled: "terms-override-enabled",
  trustedHosts: "trusted-hosts",
  userFieldsEnabled: "user-fields-enabled",
  userFieldsOrderHeader: "user-fields-order-header",
  userFieldsOrderLine: "user-fields-order-line",
  vendorProductLinesEnabled: "vendor-product-lines-enabled",
  viewOrdersUserFieldsEnabled: "view-orders-user-fields-enabled",
  warehouseCodesAllowed: "warehouse-codes-allowed",
  wholeOrderDiscountEnabled: "whole-order-discount-enabled",
  zeroPriceModEnabled: "zero-price-mod-enabled",
}

const orderStageValues = [
  { name: 0, description: 'Entered' },
  { name: 1, description: 'Ordered' },
  { name: 2, description: 'Picked' },
  { name: 3, description: 'Shipped' },
  { name: 4, description: 'Invoiced' },
  { name: 5, description: 'Paid' },
  { name: 9, description: 'Canceled' },
]

const orderStageCutoffValues = orderStageValues.map((el, idx) => {

  let val = { name: idx }

  if (idx === 0) {
    val.description = "None / Disabled"
  } else {
    let stages = slice(orderStageValues, 0, idx)
    val.description = stages.map(el => el.description).join(', ')
  }

  return val
})

const orderTypeValues = [
  { name: 'SO', description: 'Stock Order' },
  { name: 'DO', description: 'Direct Order' },
  { name: 'FO', description: 'Future Order' },
  { name: 'BL', description: 'Blanket Order' },
]

const logLevelValues = [
  { name: 'fatal', description: 'Fatal' },
  { name: 'error', description: 'Error' },
  { name: 'warn', description: 'Warn' },
  { name: 'info', description: 'Info' },
  { name: 'debug', description: 'Debug' },
  { name: 'trace', description: 'Trace' },
]

const SettingTypeObjects = [
  { name: SettingTypeNames.bodKitAlwaysPriceOverrideEnabled, description: 'BOD kits are entered as a price override', defaultValue: true, valueType: SettingType.boolean },
  { name: SettingTypeNames.chooseSalesRepCodeEnabled, description: 'Allow user to choose the sales rep code on their order', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.companyIDs, description: 'Company Numbers', defaultValue: [], valueType: SettingType.arrayofstrings },
  { name: SettingTypeNames.consignmentStockStatusFilterDefaultOptions, description: 'Consignment stock status filter default options', defaultValue: [ "stock", "direct", "order-as-needed", "do-not-reorder" ], valueType: SettingType.arrayofstrings, values: [{ name: 'stock', description: 'Stock' }, { name: 'direct', description: 'Direct' }, { name: 'order-as-needed', description: 'Order as Needed' }, { name: 'do-not-reorder', description: 'Do not Reorder' }]}, 
  { name: SettingTypeNames.contactAddEnabled, description: 'Allow user to create SX.e/CSD contacts', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.contactEditEnabled, description: 'Allow user to edit SX.e/CSD contacts', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.currencyDecimalPrecision, description: 'Currency field decimal places', defaultValue: 5, valueType: SettingType.int },
  { name: SettingTypeNames.customerAddEnabled, description: 'Customer add enabled', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.customerEditEnabled, description: 'Customer edit enabled', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.customerNotesEditingEnabled, description: 'Customer notes editing enabled', defaultValue: true, valueType: SettingType.boolean },
  { name: SettingTypeNames.defaultOrderType, description: 'Default order type', valueType: SettingType.string, values: orderTypeValues },
  { name: SettingTypeNames.defaultTakenBy, description: 'Default taken by', valueType: SettingType.string },
  { name: SettingTypeNames.defaultWarehouseID, description: 'Default warehouse (code)', valueType: SettingType.string },
  { name: SettingTypeNames.enableSelectOrderType, description: 'Enable select order type', defaultValue: false, valueType: SettingType.boolean },
  { name: SettingTypeNames.fieldUpdateAuthID, description: 'FieldUpdate API - Application ID', defaultValue: "", valueType: SettingType.string },
  { name: SettingTypeNames.fieldUpdateAuthSecret, description: 'FieldUpdate API - Consumer Key', defaultValue: "", valueType: SettingType.string },
  { name: SettingTypeNames.fogoLogLevel, description: 'Log level', defaultValue: "info", valueType: SettingType.string, values: logLevelValues },
  { name: SettingTypeNames.ignoreEdiFlags, description: 'Ignore EDI flags', valueType: SettingType.boolean },
  { name: SettingTypeNames.incompleteCountBasedEntryWarningsEnabled, description: 'Product lists - incomplete count-based entry warnings enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.itemSearchProductLabelTemplate, description: 'Item search - product label template', valueType: SettingType.string },
  { name: SettingTypeNames.itemSearchDescription1LabelTemplate, description: 'Item search - description 1 label template', valueType: SettingType.string },
  { name: SettingTypeNames.itemSearchDescription2LabelTemplate, description: 'Item search - description 2 label template', valueType: SettingType.string },
  { name: SettingTypeNames.itemSearchExtendedDescriptionLabelTemplate, description: 'Item search - extended description label template', valueType: SettingType.string },
  { name: SettingTypeNames.nonStockProductsEnabled, description: 'Non-stock products enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.orderCancelStageCutoff, description: 'Order cancel enabled for stages', valueType: SettingType.int, values: orderStageCutoffValues },
  { name: SettingTypeNames.orderCounterSaleEnabled, description: 'Point-of-sale order mode enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.orderCounterSalesShipVia, description: 'Point-of-sale order ship-via', valueType: SettingType.string },
  { name: SettingTypeNames.orderCounterSalesWarehouse, description: 'Point-of-sale warehouse', valueType: SettingType.string },
  { name: SettingTypeNames.orderDispositionEnabled, description: "Order disposition enabled", valueType: SettingType.boolean, defaultValue: true },
  { name: SettingTypeNames.orderEditEnabled, description: 'Order editing enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.orderEditStageCutoff, description: 'Order edit enabled for stages', valueType: SettingType.int, values: orderStageCutoffValues },
  { name: SettingTypeNames.orderEditStageCutoffWillCall, description: 'Order edit for will call orders enabled for stages', valueType: SettingType.int, values: orderStageCutoffValues },
  { name: SettingTypeNames.orderMarginMinimum, description: 'Minimum order margin', valueType: SettingType.float },
  { name: SettingTypeNames.orderMarginMinimumZeroRevenueMaximumLoss, description: 'Minimum order margin - zero revenue order maximum loss', valueType: SettingType.float },
  { name: SettingTypeNames.orderOriginCode, description: 'Order/Quote Origin Code', valueType: SettingType.string },
  { name: SettingTypeNames.priceOverrideEnabled, description: 'Allow price overrides', valueType: SettingType.boolean },
  { name: SettingTypeNames.productCostType, description: 'Product costs to show user', valueType: SettingType.string, values: [{ name: 'none', description: 'Do not display' }, { name: 'average', description: 'Average cost' }, { name: 'last', description: 'Last cost' }, { name: 'replacement', description: 'Replacement cost' }, { name: 'standard', description: 'Standard cost' },] },
  { name: SettingTypeNames.productCostsIncludeAddonCost, description: 'Product costs: include add on costs', valueType: SettingType.boolean },
  { name: SettingTypeNames.productListsEnabled, description: 'Product lists - enabled', valueType: SettingType.boolean, defaultValue: true, },
  { name: SettingTypeNames.productListsAddEnabled, description: 'Product lists - add new enabled', valueType: SettingType.boolean, defaultValue: false, },
  { name: SettingTypeNames.productListsEditEnabled, description: 'Product lists - edit existing enabled', valueType: SettingType.boolean, defaultValue: false, },
  { name: SettingTypeNames.productListsShipToField, description: 'Product lists - ship to field', valueType: SettingType.string, defaultValue: "user1", values: [{ name: "user1", description: 'User 1' }, { name: "user2", description: 'User 2' }, { name: "user3", description: 'User 3' }, { name: "user4", description: 'User 4' }, { name: "user5", description: 'User 5' }, ] },
  { name: SettingTypeNames.productListsFetchProductsEnabled, description: 'Product lists - refresh products on list refresh enabled', valueType: SettingType.boolean, defaultValue: true, },
  { name: SettingTypeNames.promiseDateRequired, description: 'Require order promise date', valueType: SettingType.boolean },
  { name: SettingTypeNames.quantityDecimalPrecision, description: 'Quantity field decimal places', valueType: SettingType.int },
  { name: SettingTypeNames.quoteDefaultCancelDateDuration, description: 'Quote default cancel date duration', valueType: SettingType.string, valueSubType: SettingValueSubType.dayCount, },
  { name: SettingTypeNames.refreshProductsOnScrollEnabled, description: 'Refresh product data with prices while scrolling', valueType: SettingType.boolean},
  { name: SettingTypeNames.rememberPasswordEnabled, description: 'Remember password enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.reportPrintingEnabled, description: 'Report printing enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.reportSubmitApiVersion, description: 'Report submit API version', valueType: SettingType.int, values: [{ name: 1, description: 'v1' }, { name: 2, description: 'v2' },] },
  { name: SettingTypeNames.requestedShipDateDefault, description: 'Default requested ship date is how many business days from today?', valueType: SettingType.int },
  { name: SettingTypeNames.requestedShipDateDefaultMethod, description: 'Default requested ship date method', valueType: SettingType.string, values: [{name: "business_days", description: 'Business Days'}, {name: "name_tilde_color", description: 'Name Tilde Color'}] },
  { name: SettingTypeNames.requestedShipDateRequiresConfirmation, description: 'Requested ship date requires confirmation', valueType: SettingType.boolean, defaultValue: false },
  { name: SettingTypeNames.restrictAddNewOrderEnabled, description: 'Restrict add new order enabled', valueType: SettingType.boolean, defaultValue: false },
  { name: SettingTypeNames.salesRepCodeFilterEnabled, description: 'Sales rep code filter enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.salesRepCodes, description: 'Sales rep codes', valueType: SettingType.arrayofsalesrepcodes },
  { name: SettingTypeNames.salesRepCodePrimary, description: 'Sales rep code - primary', valueType: SettingType.string },
  { name: SettingTypeNames.salesRepFilterDivision, description: 'Sales rep filter - division codes', valueType: SettingType.arrayofstrings },
  { name: SettingTypeNames.salesRepFilterTerritory, description: 'Sales rep filter - territory codes', valueType: SettingType.arrayofstrings },
  { name: SettingTypeNames.shipDateRequired, description: 'Require order requested ship date', valueType: SettingType.boolean },
  { name: SettingTypeNames.shipOrderEnabled, description: 'Ship order enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.shiptoAddEnabled, description: 'Ship to add enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.shiptoEditEnabled, description: 'Ship to edit enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.showInventoryQuantities, description: 'Show inventory quantities', valueType: SettingType.boolean, defaultValue: true, },
  { name: SettingTypeNames.signatureCaptureEnabled, description: 'Signature capture enabled', valueType: SettingType.boolean, defaultValue: false, },
  { name: SettingTypeNames.sxapiConnectionString, description: 'SX.e AppServerDC Connection String', valueType: SettingType.string },
  { name: SettingTypeNames.sxapiProductDataGeneralVersion, description: 'Product data general API version', valueType: SettingType.string, values: [{ name: '2', description: 'Version 2' }, { name: '3', description: 'Version 3' }] },
  { name: SettingTypeNames.sxapiRecordLimit, description: 'API record limit', valueType: SettingType.int },
  { name: SettingTypeNames.sxapiWarehouseDataGeneralVersion, description: 'Warehouse data general API version', valueType: SettingType.string, values: [{ name: 'disabled', description: 'Disabled' }, { name: '1', description: 'Version 1' }] },
  { name: SettingTypeNames.sxeOperatorID, description: 'CSD/SX.e operator', valueType: SettingType.string },
  { name: SettingTypeNames.sxeOperatorPassword, description: 'CSD/SX.e operator password', valueType: SettingType.string },
  { name: SettingTypeNames.tenderByAllPaymentTypesEnabled, description: 'Tendering - enabled (all payment types)', valueType: SettingType.boolean, defaultValue: false, },
  { name: SettingTypeNames.tenderOnEditOrderEnabled, description: 'Tender on edit order enabled', valueType: SettingType.boolean, defaultValue: false, },
  { name: SettingTypeNames.tenderPaymentTypeCheckCodes, description: 'Tendering - payment types with check codes', valueType: SettingType.arrayofstrings, },
  { name: SettingTypeNames.tenderPaymentTypeAmericanExpress, description: 'Tendering - payment type for American Express', valueType: SettingType.string },
  { name: SettingTypeNames.tenderPaymentTypeDiscover, description: 'Tendering - payment type for Discover', valueType: SettingType.string },
  { name: SettingTypeNames.tenderPaymentTypeMastercard, description: 'Tendering - payment type for Mastercard', valueType: SettingType.string },
  { name: SettingTypeNames.tenderPaymentTypeVisa, description: 'Tendering - payment type for Visa', valueType: SettingType.string },
  { name: SettingTypeNames.termsOverrideEnabled, description: 'Allow terms overrides', valueType: SettingType.boolean },
  { name: SettingTypeNames.userFieldsEnabled, description: 'User fields - enabled', valueType: SettingType.boolean },
  { name: SettingTypeNames.userFieldsOrderHeader, description: 'User fields - order header', valueType: SettingType.arrayofstrings, valueSubType: SettingValueSubType.userFields, },
  { name: SettingTypeNames.userFieldsOrderLine, description: 'User fields - order line', valueType: SettingType.arrayofstrings, valueSubType: SettingValueSubType.userFields, },
  { name: SettingTypeNames.vendorProductLinesEnabled, description: 'Show vendor and product lines', valueType: SettingType.boolean },
  { name: SettingTypeNames.warehouseCodesAllowed, description: 'Warehouses allowed (list of codes)', valueType: SettingType.arrayofstrings },
  { name: SettingTypeNames.wholeOrderDiscountEnabled, description: 'Whole order discount enabled', valueType: SettingType.boolean },

  // These all require mods
  { name: SettingTypeNames.holdNewOrdersWithCode, description: 'Hold all new orders with hold code', valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.invoicesByDueDateEnabled, description: 'Enable invoice searching by due date', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.orderFulfillmentEnabled, description: 'Enable order fulfillment', valueType: SettingType.boolean, defaultValue: false, requiresMod: true },
  { name: SettingTypeNames.orderFulfillmentDefaultValue, description: 'Enable order fulfillment default value', valueType: SettingType.boolean, defaultValue: false, requiresMod: true },
  { name: SettingTypeNames.orderSubmitEdiOverrideEnabled, description: 'Enable order entry override modifications', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.quantityOnOrderEnabled, description: 'Enable quantity on order', valueType: SettingType.boolean, defaultValue: false, requiresMod: true },
  { name: SettingTypeNames.reportQueue, description: "Report queue used for reports", valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.reportViewRelativePath, description: 'Report view relative path', valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.reportViewEnabled, description: "Report view enabled", valueType: SettingType.boolean, defaultValue: false, requiresMod: true, },
  { name: SettingTypeNames.reportViewPrinter, description: "Report view printer (pdf file printer)", valueType: SettingType.string, defaultValue: "", requiresMod: true, },
  { name: SettingTypeNames.requireFreightEnabled, description: "Require freight enabled", valueType: SettingType.boolean, defaultValue: false, requiresMod: true, },
  { name: SettingTypeNames.routeEditEnabled, description: 'Enable route editing on new orders', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.routeEditFormat, description: 'Default route for new orders', valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.salesRepFilterVersion, description: 'Sales rep filter version', valueType: SettingType.int, requiresMod: true, },
  { name: SettingTypeNames.shiptoEditTaxCodesEnabled, description: 'Enable editing of ship to tax codes', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.shiptoEditTermsEnabled, description: 'Enable editing of ship to terms', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.shoppingListContractItems, description: 'Enable contract items shopping list', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.signatureCaptureDownloadRelativePath, description: 'Signature capture download directory relative path', valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.signatureCaptureUploadRelativePath, description: 'Signature capture upload directory relative path', valueType: SettingType.string, requiresMod: true, },
  { name: SettingTypeNames.sxapiICGetwhseproductdatageneralv2Available, description: 'Enable use of ICGetWhseProductDataGeneralV2 API', valueType: SettingType.boolean, requiresMod: true, },
  { name: SettingTypeNames.sxapiProductCrossReferenceListVersion, description: 'Product cross reference list API version', valueType: SettingType.string, values: [{ name: '1', description: 'Version 1' }, { name: '2', description: 'Version 2' }], requiresMod: true, },
  { name: SettingTypeNames.sxapiProductCrossReferenceVersion, description: 'Product cross reference API version', valueType: SettingType.string, values: [{ name: '1', description: 'Version 1' }, { name: '1-modified', description: 'Version 1 Modified' }, { name: '2', description: 'Version 2' }, { name: '2-modified', description: 'Version 2 Modified' }], requiresMod: true, },
  { name: SettingTypeNames.tenderByCreditCardEnabled, description: 'Tender by credit card enabled', valueType: SettingType.boolean, defaultValue: false, requiresMod: true, },
  { name: SettingTypeNames.tenderByCreditCardNonRefundable, description: 'Tender by credit card - non-refundable', valueType: SettingType.string, values: [{ name: 'disabled', description: 'Disabled' }, { name: 'forced-yes', description: 'Forced Yes' }, { name: 'user-choice', description: 'User Choice' }], defaultValue: 'disabled', requiresMod: true, },
  { name: SettingTypeNames.tenderByCreditCardTransCodeEnabled, description: 'Tender by credit card - set trans code type', valueType: SettingType.boolean, defaultValue: false, requiresMod: true, },
  { name: SettingTypeNames.tenderByCreditCardTransCodeType, description: 'Tender by credit card - trans code type', valueType: SettingType.string, values: [{ name: 'auth', description: 'Authorization' }, { name: 'sale', description: 'Sale' }], defaultValue: 'auth', requiresMod: true, },
  { name: SettingTypeNames.tenderByCreditCardMethod, description: 'Tender by credit card - method', valueType: SettingType.string, values: [{ name: 'cenpos', description: 'CenPOS' }, { name: 'batch', description: 'Batch' }, { name: 'preauthorize', description: 'Pre-authorize' }], defaultValue: 'cenpos', requiresMod: true, },
  { name: SettingTypeNames.tenderMethodOnFullBackorderByShipped, description: 'Tender by credit card - tendering by shipped quantity but order is full backorder', valueType: SettingType.string, values: [{ name: 'ordered', description: 'Use quantity ordered' }, { name: 'shipped', description: "Don't tender" }, { name: 'unauthorized', description: "Credit card without pre-authorization" }], defaultValue: 'ordered', requiresMod: true, },
  { name: SettingTypeNames.zeroPriceModEnabled, description: 'Enable zero price modification', valueType: SettingType.boolean, requiresMod: true, },

  //  { name: SettingTypeNames.viewOrdersUserFieldsEnabled, description: 'view-orders-user-fields-enabled', valueType: SettingType.boolean, requiresMod: true, },
]

// Not yet implemented
// { name: SettingTypeNames.cenposCredentials, description: 'cenpos-credentials', valueType: SettingType.string },
// { name: SettingTypeNames.productDocuments, description: 'product-documents', valueType: SettingType.string },


// Probably not needed
// { name: SettingTypeNames.orderSubmitType, description: 'order-submit-type', valueType: SettingType. },
// { name: SettingTypeNames.sxapiUseUserToken, description: 'sxapi-use-user-token', valueType: SettingType.boolean },
// { name: SettingTypeNames.trustedHosts, description: 'trusted-hosts', valueType: SettingType. },

// SupplyLine only
// { name: SettingTypeNames.chooseShipViaEnabled, description: 'choose-ship-via-enabled', valueType: SettingType.boolean },
// { name: SettingTypeNames.customersAllowed, description: 'customers-allowed', valueType: SettingType.arrayofstrings },
// { name: SettingTypeNames.hideBackorder, description: 'hide-backorder', valueType: SettingType.boolean },
// { name: SettingTypeNames.homePromotionURL, description: 'home-promotion-url', valueType: SettingType.string },
// { name: SettingTypeNames.logoInNavigationBar, description: 'logo-in-navigation-bar', valueType: SettingType.boolean },
// { name: SettingTypeNames.showDiscount, description: 'show-discount', valueType: SettingType.boolean },

const SettingTypeObjectsObject = (() => {

  let types = {}

  SettingTypeObjects.forEach(element => {
    types[element.name] = element
  })

  return types
})()

const RequiredObjectTypes = [
  { code: '', name: 'None'},
  { code: 'ORDER', name: 'Order' },
  { code: 'CUSTOMER', name: 'Customer' },
  { code: 'WAREHOUSE', name: 'Warehouse' }
]

const RequiredObjectTypeForCode = code => {
  let requiredObject = RequiredObjectTypes.find(type => {
    return type.code === code
  })
  return requiredObject || { code: '', name: '' }
}

const MenuTypes = [
  { code: '', name: 'None'},
  { code: 'CUSTOMER', name: 'Customer' },
  { code: 'ORDER', name: 'Order' }
]

const MenuTypeForCode = code => {
  let menu = MenuTypes.find(type => {
    return type.code === code
  })
  return menu || { code: '', name: '' }
}

const ReportTemplateFieldTypes = [
  { code: 'ALPHANUMERIC', name: 'Alphanumeric' },
  { code: 'BOOLEAN', name: 'Boolean' },
  { code: 'BINNUMBER', name: 'Bin number' },
  { code: 'CUSTOMERNUMBER', name: 'Customer number' },
  { code: 'DATE', name: 'Date' },
  { code: 'FLOAT', name: 'Float' },
  { code: 'INTEGER', name: 'Integer' },
  { code: 'PERIODMMYY', name: 'Period MMYY' },
  { code: 'WAREHOUSE', name: 'Warehouse' },
  { code: 'YEAR', name: 'Year' },
]

const ReportTemplateFieldTypeForCode = code => {
  let ft = ReportTemplateFieldTypes.find(type => {
    return type.code === code
  })
  return ft || { code: '', name: '' }
}

const ReportTemplateFieldListItemTypes = [
  { code: 'ORDER', name: 'Order' }
]

const ReportTemplateFieldListItemTypeForCode = code => {
  let ftli = ReportTemplateFieldListItemTypes.find(type => {
    return type.code === code
  })
  return ftli || { code: '', name: '' }
}

export {
  ConnectionTypeObjects,
  connectionTypeObjectForCode,
  SettingTypeNames,
  SettingTypeObjects,
  SettingTypeObjectsObject,
  customLicenseTypePresetObject,
  LicenseTypePresetObjects,
  licenseTypePresetObjectForCode,
  SalesRepCodeTypeNames,
  SalesRepCodeTypeObjects,
  SalesRepCodeTypeObjectForCode,
  DayCountUnitTypeNames,
  DayCountUnitTypeObjects,
  DayCountUnitTypeObjectForCode,
  UserFieldValueTypeNames,
  UserFieldValueTypeObjects,
  UserFieldValueTypeObjectForCode,
  RequiredObjectTypes,
  RequiredObjectTypeForCode,
  MenuTypes,
  MenuTypeForCode,
  ReportTemplateFieldTypes,
  ReportTemplateFieldTypeForCode,
  ReportTemplateFieldListItemTypes,
  ReportTemplateFieldListItemTypeForCode
}