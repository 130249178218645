import gql from 'graphql-tag'

const ConsoleViewerQuery = gql`
query ConsoleViewer {
  consoleViewer {
    id
    name
    email
    amSystemAdmin
    accounts {
      id
      name
      enabled
    }
  }
}
`

export {
    ConsoleViewerQuery
}

