import React, { Component } from 'react'
import { graphql } from "react-apollo"
import { ConsoleViewerQuery } from '../../schema/queries/ViewProvider'

const ViewerContext = React.createContext({ })

class ViewerProviderEmpty extends Component {

  render() {

    let data = this.props.data

    let viewer = data.consoleViewer || { }
    if (data.loading) {
      viewer.loading = data.loading
    }

    return(
      <ViewerContext.Provider value={viewer || { }}>
        {this.props.children}
      </ViewerContext.Provider>
    )
  }
}

const ViewerProvider = graphql(ConsoleViewerQuery)( ViewerProviderEmpty )

export { ViewerContext, ViewerProvider }