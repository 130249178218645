import React, { Component } from 'react'
import { ViewerContext } from './ViewerProvider'
import { Redirect } from 'react-router-dom'
import { PropTypes } from 'prop-types'

class Admin extends Component {

  render() {

    let failCase = null

    if (this.props.redirectTo) {
      failCase = <Redirect to={this.props.redirectTo} />
    }

    return(
      <ViewerContext.Consumer>
        { viewer => (viewer.accounts || []).length > 0 ? this.props.children : failCase }
      </ViewerContext.Consumer>
    )
  }
}

Admin.propTypes = {
  redirectTo: PropTypes.string,
}

class NotAdmin extends Component {

  render() {

    let failCase = null

    if (this.props.redirectTo) {
      failCase = <Redirect to={this.props.redirectTo} />
    }

    return(
      <ViewerContext.Consumer>
        { viewer => (viewer.accounts || []).length > 0 ? failCase : this.props.children }
      </ViewerContext.Consumer>
    )
  }
}

NotAdmin.propTypes = {
  redirectTo: PropTypes.string,
}

export { Admin, NotAdmin }

