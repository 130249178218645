export const SettingValue = `
  fragment SettingValue on Setting {
    name
    userGroup {
      id
      name
    }
    connection {
      id
      name
    }
    ... on BooleanSetting {
      valueBoolean
    }
    ... on StringSetting {
      valueString
    }
    ... on FloatSetting {
      valueFloat
    }
    ... on IntSetting {
      valueInt
    }
    ... on ArrayOfStringsSetting {
      valueStrings
    }
    ... on ArrayOfSalesRepCodesSetting {
      valueSalesRepCodes {
        code
        type
      }
    }
  }
`

export function Settings(parent) {
  return `
    fragment ${parent}Settings on ${parent} {
      settingsHierarchy {
        id
        name
      }
      settings {
        ...SettingValue
      }
      inheritedSettings {
        ...SettingValue
      }
    }
    ${SettingValue}
  `
}