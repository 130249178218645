import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from '../components/Status/LoadingIndicator'

function removeLastPathComponent(path) {
  let comps = path.split('/')
  comps.pop()
  return comps.join('/')
}

function loading() {
  return <div><LoadingIndicator /></div>
}

function createLoadable(createModule) {
  return Loadable({
    loader: createModule,
    loading: loading,
  })
}

export { removeLastPathComponent, createLoadable }