import React, { Component } from 'react'
import { Spinner } from './Spinner'

class LoadingIndicator extends Component {
  render() {
    return (
      <span className='align-middle'>Loading...{'  '}
        <Spinner className='align-middle' size="1.5" ratio="0.1" />
      </span>
    )
  }
}

export default LoadingIndicator
export { LoadingIndicator }